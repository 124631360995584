<template>
  <Logo />
  <But />
  <div class="ab">
    <h1>О клинике</h1>
    <div class="kn">
      <div>
        <h4>
          Добро пожаловать в стоматологическую клинику «ЭКОДЕНТ»!
        </h4>  
        <p>
          Десять фактов о нашей клинике:
        </p>
        <ol>
          <li>День рождения клиники – 01 сентября.</li>
          <li>Медицинская деятельность стоматологии лицензирована. В клинике используется 
современное оборудование и только сертифицированные материалы и препараты.
          </li>
          <li>
            В стоматологии средние по городу Чебоксары цены на услуги. Периодически проводятся акции (информация размещается в соответствующем разделе сайта, в группах «ЭКОДЕНТА» в Контакте и Телеграмме).
          </li>
          <li>
            В стоматологии «ЭКОДЕНТ» оказывают широкий спектр услуг: <br>
* Диагностика *Лечение *Профилактика *Отбеливание *Эстетическая реставрация *Имплантация *Удаление *Протезирование *Ортодонтия. 
Со всем спектром услуг вы можете ознакомиться в соответствующем разделе сайта.
          </li>
          <li>
            Врачи клиники искренне заинтересованы в результатах своей работы. Они постоянно совершенствуют свои профессиональные навыки, проходят обучение, посещают семинары, конгрессы, авторские курсы и мастер-классы.
          </li>
          <li>
            В «ЭКОДЕНТЕ» установлено современное стоматологическое оборудование, позволяющее решать сложные клинические задачи.
          </li>
          <li>
            В клинике бережно и внимательно относятся к пациентам. Подход к каждому пациенту индивидуален. Здесь подберут оптимальное решение вашей проблемы.
          </li>
          <li>
            О визите к стоматологу вам напомнят СМС сообщением.
          </li>
          <li>В помещениях клиники созданы комфортные условия.</li>
          <li>
            Стоматология «ЭКОДЕНТ» расположена в 5 минутах ходьбы от Национальной библиотеки города Чебоксары. <br>
            Для маломобильных групп населения/инвалидов вход в клинику оборудован  пандусом и кнопкой вызова.
          </li>
        </ol>
        <div>          
        </div>
      </div>
      <a-carousel autoplay arrows effect="fade">
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
        <div><img class='ii' alt="" src="../assets/e1.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e2.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e3.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e4.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e5.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e6.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e7.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e8.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e9.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e10.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e11.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/e12.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/dii4.jpg" /></div>
      </a-carousel>
    </div>
    <a href="tel:+79530197374"><button>Записаться на прием</button></a>
  </div>
  <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Akkord from '@/components/Akkord.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
  name: 'AboutView',
  components: {
    Logo,
    Akkord,
    Footer,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
    But,
  }
}
</script>

<style scoped>
.ab {
  /* min-height: 100vh; */
  /* background-image: url(../assets/fon2.jpg);
  background-size: cover; */
  padding: 5vh 3.5vw;
}

.kn {
  display: grid;  
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

h4 {
  font-size: 1.7vw;
}

p {
  width: 43vw;
  font-size: 1.3vw;
}

li {
  font-size: 1.2vw;
  width: 43vw;
}

/* .but {
  display: flex;
  justify-content: end;
} */

button {
  margin-top: 5vh;
  margin-bottom: 5vh;
  background: none;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

.ii {
  width: 100%;
  min-height: 30vh;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  width: 100%;
  min-height: 30vh;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3vw;
  height: 25px;
  font-size: 3vw;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

  .ab {
    padding: 5em 1em;
  }

  .kn {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  h1 {
    margin-left: 5px;
    font-size: 24px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    width: 100%;
    font-size: 19px;
  }

  li {
    font-size: 17px;
    width: 100%;
  }

  .but {
    justify-content: center;
    width: 100%;
  }

  button {
    width: 100%;
    font-size: 19px;
    margin-top: 70px;

    opacity: 1;
  }

  .ii {
    width: 100%;
  }
  
  .ant-carousel :deep(.slick-slide) {
    margin-top: 40px;
  }

  .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 9vw;
    font-size: 9vw;
    margin-top: 20px;
  }
  
}
</style>
