<template>
    <h1>Такой страницы не существует</h1>
    <nav>
        <router-link to="/"><button>Перейти на главную страницу</button></router-link>
    </nav>
</template>

<script>
export default {
    name: 'NotFoundComponent'
}
</script>

<style scoped>
h1 {
    margin-top: 50px;
    text-align: center;
}

button {
    margin: 50px 0px 0 0px;
    padding: 0;
    width: 100%;
    height: 50px;
    background-color: blue;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
}
</style>