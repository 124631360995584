<template>
    <Logo />
    <But />
    <div class="tt">
        <h2>
            Правила подготовки к приему стоматолога, диагностическим исследованиям, рентгенологическому обследованию (прицельному снимку), наличие противопоказаний для посещения стоматолога
        </h2>
        <div class="tm">
            <p>
                Особой подготовки к посещению врача не требуется. Если есть какие-то особые требования перед процедурой, врач или администратор предупредят о них пациента.
            </p>
            <p>
                Чтобы визит к стоматологу прошел удачно, нужно не забывать о следующих мерах:
            </p>
            <ul>
                <li>
                    Не надо отправляться к врачу натощак, лучше незадолго до назначенного времени перекусить - у сытого человека выделяется меньше слюны.
                </li>
                <li>
                    Тщательно почистите зубы.
                </li>
                <li>
                    Если чувствуете сильное волнение накануне вечером и перед самым днем приема можно выпить что-нибудь успокаивающее, например, валерьянку или пустырник (есть противопоказания). Не принимайте алкоголь.
                </li>
                <li>
                    Перед первым визитом необходимо взять паспорт, он потребуется для оформления медкарты.
                </li>
                <li>
                    Взять с собой (при их наличии) результаты прежних обследований, рентгеновские снимки.
                </li>
                <li>
                    Врач будет задать вопросы о наличии заболеваний, проблем, аллергии. Необходимо указать, на какие вещества и препараты может возникнуть аллергическая реакция.
                </li>
                <li>
                    Если перед посещением врача обострилось какое-либо хроническое заболевание (даже не связанное с ротовой полостью), желательно позвонить врачу и уточнить, возможно ли посещение. В некоторых случаях лечение зубов противопоказано и нужно отложить процедуру до стабилизации состояния здоровья.
                </li>
                <li>
                    Диагностические исследования заключаются в обследовании специалистами 
и\или рентгенологическом исследовании, все они не требуют специальной подготовки. 
                </li>
                <li>
                    Для того, чтобы результат обследования получился максимально качественным, во время процедуры мы рекомендуем дышать ровно, спокойно, без резких вдохов и выдохов; 
                </li>
            </ul>
            <p>
                Уважаемые пациенты, обращаем ваше внимание, что прием осуществляется по предварительной записи.
            </p>
        </div>
        <div class="tm">
            <a href="tel:+79530197374"><button>Записаться на прием</button></a>
        </div>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
    name: "TherapyView",
    components: {
        Logo,
        Footer,
        But,
    }
}
</script>

<style scoped>
.tt {
    min-height: 100vh;
    padding: 5vh 3.5vw;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

h2 {
    font-size: 2vw;
}

p {
  font-size: 1.3vw;
}

li {
    font-size: 1.2vw;
}

.tm {
    
}

/* img {
    max-width: 1000px;
} */

button {
  margin-top: 50px;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  margin-bottom: 20px;
 
 opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .tt {
        padding: 5em 1em;
    }

    h1 {
        margin-left: 5px;
        font-size: 24px;
    }

    h2 {
        font-size: 28px;
    }

    p {
        font-size: 19px;
    }

    li {
        font-size: 15px;
    }

    /* img {
        width: 100%;
    } */

    button,
    a {
        width: 100%;
        font-size: 19px;

        opacity: 1;
    }
    
}
</style>