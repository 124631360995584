<template>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h3>Какие услуги оказывает клиника?</h3>
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Услуги, оказываемые стоматологической клиникой "ЭКОДЕНТ": <br> * Диагностика *Лечение *Профилактика *Отбеливание *Эстетическая реставрация *Имплантация *Удаление *Протезирование *Ортодонтия. Более подробно с услугами вы можете ознакомиться в соответствующем разделе нашего сайта. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h3>Какие препараты в вашей клинике используются для анестезии?</h3>
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Для обезболивания при лечении зубов стоматология «Экодент» использует местную, инфильтрационную либо проводниковую анестезию препаратами артикаинового ряда (артикаин, убистезин, ораблок).  
Это универсальные препараты, которые имеют широкое применение. Кроме основного компонента (анестетика) они содержат сосудосуживающее вещество (эпинефрин). При сужении сосудов в зоне инъекции уменьшается вымывание анестетика, за счет этого повышается эффективность и продолжительность обезболивающего действия. Такая анестезия воздействует только на периферическую нервную систему (не отключает сознание пациента). После введения анестетика возникает чувство онемения в десне, языке и губах. Действует от 40 минут до 2-х часов. За это время врач-стоматолог успевает провести все необходимые манипуляции. 
Примерно через 20-25 минут половина препарата артикаинового ряда уже выводится из организма, в то время как для лидокаина, чтобы вывелась половина дозы препарата, нужно около 90 минут.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <h3>Можно ли проводить лечение зубов с анестезией беременным, кормящим мамам?</h3>
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Обязательно до начала лечения предупредите доктора об имеющейся ситуации - он  подберет индивидуально необходимый анестетик и его дозировку. Рекомендуется проводить стоматологическое лечение во втором триместре беременности, это самый безопасный период как для матери, так и для ребенка. Местная проводниковая анестезия препаратами артикаинового ряда, применяемая в клинике "Экодент", показана во время беременности и при грудном вскармливании. 
            </p>
          </div>
        </div>
      </div>
       <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <h3>Сколько будет стоить лечение одного зуба?</h3>
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Со стоимостью на услуги вы можете ознакомиться в разделе нашего сайта "Цены". Чтобы ответить на вопрос сколько будет стоить лечение ваших зубов необходимо пройти консультативно-диагностический прием у врача-стоматолога. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <h3>Могу ли я воспользоваться вашими услугами по ДМС?</h3>
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              В перспективе  клиника "Экодент" планирует работать по полису ДМС.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            <h3>Принимаете ли вы детей?</h3>
          </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Клиника "Экодент" принимает пациентов от 18 лет и старше.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            <h3>Можно у вас удалить зуб и сразу поставить имплант на его место?</h3>
          </button>
        </h2>
        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Это возможно. Эта процедура называется - одномоментная имплантация. То есть, Вам удалят зуб, установят имплантат и на имплантат поставят временный зуб, всё это в одно посещение. После приживления имплантата (от 3 до 6 месяцев, индивидуально) временная конструкция заменяется на постоянную. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEight">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
            <h3>Сколько у вас будет стоить удаление зуба мудрости?</h3>
          </button>
        </h2>
        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Со стоимостью на услуги вы можете ознакомиться в разделе нашего сайта "Цены". В среднем стоимость будет от 5000 рублей. Чтобы ответить на вопрос сколько будет стоить лечение ваших зубов необходимо пройти консультативно-диагностический прием у врача-стоматолога-хирурга клиники. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingNine">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            <h3>Сколько стоит делать импланты?</h3>
          </button>
        </h2>
        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Со стоимостью на услуги вы можете ознакомиться в соответствующем разделе нашего сайта. Чтобы ответить на вопрос, сколько будет стоить лечение ваших зубов, необходимо пройти консультативно-диагностический прием у врача-стоматолога-хирурга клиники. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
            <h3>Сколько стоит чистка зубов в клинике «Экодент»?</h3>
          </button>
        </h2>
        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              В клинике проводится  ультразвуковая чистка, воздушно-абразивная обработка зубов. В среднем стоимость профессиональной гигиены - от 2000 рублей. Чтобы узнать точную цену нужно пройти осмотр стоматолога клиники.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEleven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
            <h3>Что делать, если у меня повышенный рвотный рефлекс?</h3>
          </button>
        </h2>
        <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Рекомендуем за 40 минут до приема у стоматолога принимать противорвотные препараты, например церукал (имеются противопоказания).
            </p>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
export default {
name: 'Akkord'
}
</script>

<style scoped>

.accordion {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 8vh;
}

.accordion-item {
  background: #fff;
}

.accordion-button {
  width: 100%;
  background: #ceface;
  text-shadow: none;
}

button:hover {
  background: white;
  border-color: green;
  color: green;
  transition: all 0.4s ease;
  text-shadow: none;
}

h2 {
  font-size: 2vw;
}

h3 {
  color: green;
  margin-bottom: 0;
  font-size: 1.3vw;
  width: 38vw;
}

p {
  margin-top: 10px;
  min-width: 40vw;
  font-size: 1.1vw;
}

@media (max-width: 790px) {

  h2 {
    /* margin-top: 30px; */
    font-size: 30px;
  }

  h3 {
    font-size: 19px;
    width: 90%;
  }

  p {
    width: 100%;
    font-size: 17px;
  }

  button {
    width: 100%;
  }

  .accordion {
    margin-top: 50px;
  }

  .accordion-item {
    border-width: 0;
  }

  .accordion-button {
    min-height: 70px;
  }
    
}

</style>