<template>
    <Logo />
    <But />
    <div class="hh">
        <h1>Цены</h1>
        <a href="tel:+79530197374"><button>Записаться на прием</button></a>
        <p>
            Формы оплаты за услуги, предоставляемые ООО «ЭКОДЕНТ» - наличными деньгами или безналичным порядком с банковских карт.
        </p>
        <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
                <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                    <left-circle-outlined />
                </div>
            </template>
            <template #nextArrow>
                <div class="custom-slick-arrow" style="right: 10px">
                    <right-circle-outlined />
                </div>
            </template>
                <div><img class='ii' alt="" src="../assets/1.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/2.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/3.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/4.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/5.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/6.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/7.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/8.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/9.jpg" /></div>
                <div><img class='ii' alt="" src="../assets/10.jpg" /></div>
        </a-carousel>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
    name: 'PricesView',
    components: {
        Logo,
        Footer,
        ACarousel: Carousel,
        LeftCircleOutlined, 
        RightCircleOutlined,
        But,
    }
}
</script>

<style scoped>
.hh {
    padding: 5vh 3.5vw;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

p {
    font-size: 1.4vw;
    margin-top: 4vh;
}

button {
  margin: 2vh 38vw;
  padding: 0;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease; 

  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

.ii {
    width: 80%;
    margin: auto;
    min-height: 30vh;
    overflow: hidden;
    object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
    text-align: center;
    width: 100%;
    min-height: 30vh;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3vw;
  height: 25px;
  font-size: 3vw;
  color: red;
  background-color: rgba(255, 255, 255, 0.11);
  opacity: 1;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

    .hh {
        padding: 5em 1em;
    }

    button {
        width: 100%;
        font-size: 19px;
        margin: 5px auto;

        opacity: 1;
    }

    h1 {
        margin-left: 5px;
        font-size: 24px;
    }

    p {
        font-size: 19px;
    }

    .ii {
        width: 100%;
    }
    
    .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
        width: 9vw;
        font-size: 9vw;
        margin-top: 20px;
        opacity: 0.3;
    }

}
</style>