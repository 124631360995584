<template>
  <Logo />
  <But />
  <div class="ab">
    <h1>Наши врачи</h1>
    <Akkord />
    <a href="tel:+79530197374"><button>Записаться на прием</button></a>
  </div>
  <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Akkord from '@/components/Akkord.vue'
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
  name: 'AboutView',
  components: {
    Logo,
    Akkord,
    Footer,
    But,
  }
}
</script>

<style scoped>
.ab {
  /* min-height: 100vh; */
  /* background-image: url(../assets/fon2.jpg);
  background-size: cover; */
  padding: 5vh 3.5vw;
}

.f1 {

}

.kn {
  margin-top: 10vh;
  display: grid;  
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

p {
  width: 43vw;
  font-size: 1.3vw;
}

li {
  font-size: 1.2vw;
  width: 43vw;
}

button {
  margin-top: 8vh;
  margin-bottom: 5vh;
  background: none;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

.ii {
  width: 100%;
  min-height: 30vh;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  width: 100%;
  min-height: 30vh;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3vw;
  height: 25px;
  font-size: 3vw;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

  .ab {
    padding: 5em 1em;
  }

  .kn {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }

  h1 {
    margin-left: 5px;
    font-size: 24px;
  }

  p {
    width: 100%;
    font-size: 19px;
  }

  li {
    font-size: 17px;
    width: 100%;
  }

  button {
    width: 100%;
    font-size: 19px;
    
    opacity: 1;
  }

  .ii {
    width: 100%;
  }
  
  .ant-carousel :deep(.slick-slide) {
    margin-top: 40px;
  }

  .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 9vw;
    font-size: 9vw;
    margin-top: 20px;
  }
  
}
</style>
