<template>
    <Logo />
    <But />
    <div class="tt">
        <h1>Вопрос-ответ</h1>
        <div class="tm">
            <div class="cont">
                <button 
                    type="button" 
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal"
                    class="bzv"
                >
                    Задайте вопрос
                </button>
                <Modal />
                <Akkord2 />
                <div>
                    <a href="tel:+79530197374"><button>Записаться на прием</button></a>
                </div>
            </div>
            <a-carousel autoplay arrows effect="fade">
                <template #prevArrow>
                    <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                        <left-circle-outlined />
                    </div>
                </template>
                <template #nextArrow>
                    <div class="custom-slick-arrow" style="right: 10px">
                        <right-circle-outlined />
                    </div>
                </template>
                    <div><img class='ii' alt="" src="../assets/vo1.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/vo2.jpg" /></div>
            </a-carousel>
        </div>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Modal from '@/components/Modal.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Footer from '@/components/Footer.vue'
import Akkord2 from '@/components/Akkord2.vue'
import But from '@/components/But.vue'

export default {
    name: "TherapyView",
    components: {
        Logo,
        Modal,
        Footer,
        ACarousel: Carousel,
        LeftCircleOutlined, 
        RightCircleOutlined,
        Akkord2,
        But,
    }
}
</script>

<style scoped>
.tt {
    padding: 5vh 3.5vw;
    min-height: 100vh;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

.tm {
    margin-top: 4vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.cont {
    min-width: 43vw;
    margin-right: 4vw;
}

button {
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  margin-bottom: 4vh;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

.bzv {
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  margin-bottom: 4vh;

  opacity: 1;
}

.bzv:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

.ii {
    width: 100%;
    min-height: 30vh;
    overflow: hidden;
    object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
    text-align: center;
    width: 100%;
    min-height: 30vh;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3vw;
  height: 25px;
  font-size: 3vw;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

    .tt {
        padding: 5em 1em;
    }

    .tm {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .cont {
        margin-right: 0;
    }

    h1 {
        font-size: 24px;
    }
   
    button {
        width: 100%;
        font-size: 19px;
        margin-top: 20px;
        margin-bottom: 0px;

        opacity: 1;
    }

    .bzv {
        font-size: 19px;
    }

    .ii {
        width: 100%;
    }
    
    .ant-carousel :deep(.slick-slide) {
        margin-top: 70px;
    }

    .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
        width: 9vw;
        font-size: 9vw;
        margin-top: 20px;
    }
    
}
</style>