<template>
    <Logo />
    <But />
    <div class="kk">
        <h1>Контакты</h1>
        <div class="kn">
            <div class="pp">
                <p>г. Чебоксары, ул. Николая Рождественского, 1</p>
                <p class="pf">Режим работы: <br>
                    ПН-ПТ 08:00 - 20:00, <br>
                    СБ 09:00 - 15:00, <br>
                    ВС выходной
                </p>
                <a href="https://vk.com/ecodent21" target="_blank" rel="noopener">
                    <img class='vk' alt="" src="../assets/vk.jpg"/>
                </a>
                <a class="btn_telegram_share" href="https://t.me/+6meMgc7Np8VmZDYy" target="_blank" rel="noopener">
                    <img class='vk' alt="" src="../assets/telega.png"/>
                </a>
                <br><br>
                <p>тел. <a class="tel" href="tel:+79530197374">+7 (953) 019-73-74</a></p>
                <p>e-mail: <a class="em" href="mailto:ecodent21@mail.ru">ecodent21@mail.ru</a></p>
                <a href="tel:+79530197374"><button>Записаться на прием</button></a>
            </div>
            <div class="map">
                <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/ekodent/58719592682/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Экодент</a><a href="https://yandex.ru/maps/45/cheboksary/category/dental_clinics/184106132/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Стоматологическая клиника в Чебоксарах</a><iframe src="https://yandex.ru/map-widget/v1/-/CCUvRFEttA" width="600" height="560" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
    name: 'ContactsView',
    components: {
        Logo,
        Footer,
        But,
    }
}
</script>

<style scoped>
.kk {
    min-height: 100vh;
    padding: 5vh 3.5vw;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

.kn {
  display: grid;  
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

.vk {
    width: 4vw;
    margin-right: 2vw;
}

.pp {
    margin: 0px 0 50px 0;
}

.tel {
    text-decoration: none;
    font-size: 1.7vw;
    color: #000;
}

.tel:hover {
    color: blue;
}

.em {
    font-size: 1.7vw;
    color: #000;
}

.em:hover {
    color: blue;
}

p {
    color: #000;
    font-size: 1.5vw;
    margin-bottom: 0;
}

button {
  margin-top: 35px;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

iframe {
    width: 100%;
}

@media (max-width: 790px) {

    .kk {    
        padding: 5em 1em;
    }

    .kn {
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    }

    .pp {
        margin: 0px 0 20px 0;
    }

    p {
        font-size: 19px;
    }

    .vk {
        width: 50px;
        margin-top: 5px;
    }

    button {
        width: 100%;
        font-size: 19px;
        margin-top: 10px;

        opacity: 1;
    }

    h1 {
        margin-left: 5px;
        font-size: 24px;
    }

    .tel,
    .em {
        font-size: 21px;
    }

}
</style>