<template>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h3>Правила ухода и  пользования несъемными зубными протезами</h3>
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              К несъемным протезам относятся виниры, вкладки, накладки, коронки, 
мостовидные протезу, протезирование на имплантах.
            </p>
            <ul>
              <li>
                Обязательно помните о  гигиене полости рта. Необходимо чистить 
зубы, язык, щеки и десны не менее двух раз в день. 
              </li>
              <li>
                За несъемными зубными протезами необходимо ухаживать так же, 
как за естественными зубами.
              </li>
              <li>
                Чистить зубной щеткой с зубной пастой не менее двух раз в день. 
Рекомендуется пользоваться ирригатором.
              </li>
              <li>
                После каждого приема пищи следует полоскать полость рта водой. 
              </li>
              <li>
                 Если протез зафиксирован на временный цемент, не рекомендуется 
жевать липкие или вязкие продукты.
              </li>
              <li>
                Во избежание поломки протеза, скола облицовочного материала, а 
также повреждения слизистой оболочки полости рта не рекомендуется 
принимать и пережевывать очень жесткую пищу (например, сухари), 
откусывать от больших кусков (например, от цельного яблока).
              </li>
              <li>
                Необходимо обратиться в клинику к врачу-стоматологу-ортопеду в 
следующих случаях:
              </li>
              <ul>
                <li>
                  при нарушении фиксации несъемного протеза (признаками нарушения 
фиксации могут быть «хлюпанье» протеза, неприятный запах изо рта);
                </li>
                <li>
                  в случае скола облицовочного материала;
                </li>
                <li>
                  при появлении болезненных ощущений в области коронок, воспаления 
десны вокруг коронки (кровоточивость десны).
                </li>
              </ul>
              <li>
                Посещайте раз в полгода профилактические осмотры у врача-
стоматолога-ортопеда для проверки правильности прилегания зубных 
протезов.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h3>Правила ухода и пользования съемными зубными протезами</h3>
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              К съемным протезам относятся пластинчатые, частично-съемные и 
полные съемные протезы, бюгельные протезы.
            </p>
            <ul>
              <li>
                Съемные зубные протезы необходимо чистить только мягкой 
зубной щеткой с зубной пастой без абразива или с детским мылом два раза в 
день (утром и вечером), а также после еды по мере возможности.
              </li>
              <li>
                Обязательно помните о  гигиене полости рта. Необходимо чистить 
зубы, язык, щеки и десны не менее двух раз в день. Даже если у вас остался 
один зуб – его необходимо чистить.
              </li>
              <li>
                Во избежание поломки протеза, а также повреждения слизистой 
оболочки полости рта не рекомендуется принимать и пережевывать очень 
жесткую пищу (например, сухари), откусывать от больших кусков 
(например, от целого яблока).
              </li>
              <li>
                Бюгельные протезы не нужно снимать на ночь, в них можно спать. 
Пластинчатые протезы на ночь необходимо снимать и после чистки хранить 
ночью в специальном контейнере.  
              </li>
              <li>
                Во избежание поломки не допускайте падения протезов на твердую 
поверхность. Их нельзя мыть кипятком. 
              </li>
              <li>
                Съемные протезы должны ежедневно проходить дезинфекцию с 
помощью специальных средств, которые продаются в аптеках (Корега, 
Protefix, Lacalut и другие). Для дезинфекции также можно использовать 
ультразвуковые ванночки.
              </li>
              <li>
                Каждые полгода съемные протезы рекомендуется продвергать 
профессиональной чистке у стоматолога.
              </li>
              <li>
                Для проверки правильности прилегания зубных протезов посещайте 
раз в полгода профилактические осмотры у врача-стоматолога-ортопеда.
              </li>
              <li>
                В случае поломки или возникновении трещины в базисе съемного 
протеза срочно обратитесь в клинику к врачу-стоматологу-ортопеду.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <h3>Прием пищи после установки световой пломбы</h3>
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Специалисты клиники «Экодент» придерживаются мнения, что после 
установки световой пломбы можно есть сразу. Объясняется это свойствами 
фотополимеров. Материал затвердевает под действием лучей ультрафиолета, 
а значит, уже по завершении процедуры, пломба не может быть подвержена 
внешнему воздействию. 
            </p>
            <p>
              Однако следует отказаться от приема пищи, пока действует анестезия - 
из-за риска прикусить губу. 
            </p>
            <p>
              Не употребляйте красящие продукты 4 часа после лечения, а в случае, 
если вам восстанавливали фронтальную группу зубов – сутки:
            </p>
            <ul>
              <li>кофе;</li>
              <li>свекла;</li>
              <li>морковь;</li>
              <li>черника;</li>
              <li>газированные напитки;</li>
              <li>красное вино, коньяк;</li>
              <li>шоколад и какао;</li>
              <li>жевательная резинка;</li>
              <li>орехи, грильяж, сухари.</li>
            </ul>
            <p>В незначительных количествах можно употреблять:</p>
            <ul>
              <li>чай черный;</li>
              <li>клубника;</li>
              <li>черешня;</li>
              <li>соки натуральные.</li>
            </ul>
            <p>
              Исключите твердые продукты (грильяж, сухарики) из рациона, если у 
вас большая реставрация зубов.
            </p>
            <p>
              После установки световой пломбы не ешьте слишком горячие или 
холодные продукты. Контраст температур может привести не только к 
повреждению пломбы, но и к поражению тканей самого зуба.
            </p>
          </div>
        </div>
      </div>
       <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <h3>Рекомендации по профилактике заболеваний зубов и полости рта</h3>
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                Посещайте стоматолога в целях профилактики 1- 2 раза в год.
              </li>
              <li>
                Чистите зубы не менее 2-х раз в день: утром после завтрака и 
вечером перед сном с соблюдением правильной техники чистки зубов.
              </li>
              <li>
                Чистите поверхность языка, в том числе с помощью скребка для 
языка.
              </li>
              <li>
                Пользуйтесь при чистке зубов не только зубной щеткой, но и 
зубными нитями, зубочистками, межзубными ершиками, ополаскивателями 
полости рта. Хорошо зарекомендовали себя.
              </li>
              <li>
                Зубную щетку подбирайте индивидуально в зависимости от 
состояния полости рта. Меняйте зубную щетку через каждые 2-3 месяца.
              </li>
              <li>
                После каждого приема пищи прополаскивайте рот водой.
              </li>
              <li>
                Пользуйтесь ирригатором – прибором для гигиены ротовой 
полости, который позволяет удалять зубной налет и бактерии направленной 
струей жидкости.
              </li>
              <li>
                Ограничивайте потребление углеводистой пищи, сахара.
              </li>
              <li>
                Включайте в ежедневный рацион овощи и фрукты.
              </li>
              <li>
                Употребляйте фторо- и кальцийсодержащую пищу 
(кисломолочные продукты, рыбу и др.).
              </li>
              <li>
                Насыщайте организм витаминами (С, Е, гр.В), особенно в 
весенне-зимний период.
              </li>
              <li>
                Ведите здоровый образ жизни, укрепляйте здоровье.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <h3>Уход за полостью рта в первые дни после имплантации зубов</h3>
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                Не принимайте пищу и не пейте в течение 2-х часов после 
операции.
              </li>
              <li>
                Для курящих – не курите в первые сутки после операции.
              </li>
              <li>
                Первые 2 дня откажитесь от употребления липких, вязких, жестких 
продуктов, чрезмерно горячих или холодных, острых блюд.
              </li>
              <li>
                После каждого приема пищи ополаскивайте полость рта водой, 
затем  делайте ротовые ванночки 0,05% водным раствором хлоргексидина.
              </li>
              <li>
                В течение дня после операции для профилактики отеков  
необходимо каждые два часа по 10 минут прикладывать холод к щеке с 
наружной стороны в области имплантации. На следующий день после 
операции может появиться небольшой отек мягких тканей лица, 
прилегающих к месту операции — это нормальная реакция организма на 
проведенное хирургическое вмешательство.
              </li>
              <li>
                Принимать болеутоляющие препараты, рекомендованные врачом, 
нужно по необходимости.
              </li>
              <li>
                Первые 2-3 дня после операции спите на противоположной стороне 
или на спине.
              </li>
              <li>
                В первые 14 дней после операции исключите перегревание и 
переохлаждение, занятия физическими упражнениями.
              </li>
              <li>
                Первую чистку зубов лучше отложить на следующее утро после 
операции. Чистите зубы не менее двух раз в день зубной щеткой мягкой или 
средней жесткости с зубной пастой без агрессивных и твердых частиц. 
Очищайте язык от налета.
              </li>
              <li>
                При усилении болевых ощущений, повышении температуры выше 
37℃, сильном отеке немедленно обратитесь к врачу-стоматологу.
              </li>
              <li>
                Посещайте раз в полгода профилактические осмотры у врача-
стоматолога.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            <h3>Уход за полостью рта в первые дни после протезирования зубов</h3>
          </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                Наберитесь немного терпения, зубные протезы требуют 
привыкания.
              </li>
              <li>
                Не принимайте пищу в течение 2-3 часов после процедуры.
              </li>
              <li>
                В первые 2-3 дня после процедуры по возможности не нагружайте 
коронку - не используйте ее для разжевывания, жуйте другой стороной 
челюсти.
              </li>
              <li>
                Откажитесь от употребления липких, вязких, жестких продуктов.
              </li>
              <li>
                Откажитесь от чрезмерно горячих или холодных блюд.
              </li>
              <li>
                Не снимайте зубные протезы в течение времени, обозначенного 
стоматологом-ортопедом.
              </li>
              <li>
                При появлении болевых ощущений или потертостей немедленно 
обратитесь к врачу-стоматологу-ортопеду.
              </li>
              <li>
                Регулярно – утром и вечером чистите свои зубные протезы.
              </li>
              <li>
                Чистите зубы не менее двух раз в день зубной щеткой мягкой с 
зубной пастой.
              </li>
              <li>
                После каждого приема пищи ополаскивайте полость рта водой.
              </li>
              <li>
                Посещайте раз в полгода профилактические осмотры у врача-
стоматолога-ортопеда для проверки правильности прилегания зубных 
протезов.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            <h3>Уход за полостью рта после удаления зуба</h3>
          </button>
        </h2>
        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              После удаления зуба нельзя принимать пищу и пить два часа.
            </p>
            <p>
              В день удаления исключить прием горячей, а также жесткой и твердой пищи.
            </p>
            <p>
              Если вам удалили зуб мудрости или было сложное удаление необходимо в течение дня 
каждые два часа по 10 минут прикладывать холод к щеке с наружной стороны в области 
удаления. 
            </p>
            <p>
              В день удаления зуба после каждого приема пищи делайте ротовые ванночки 0,05% 
водным раствором хлоргексидина – просто наберите раствор в рот, немного подержите и 
аккуратно выплюньте его.
            </p>
            <p>
              Принимать болеутоляющие препараты, рекомендованные врачом, нужно по 
необходимости – на ночь или когда пройдет действие анестезии. 
            </p>
            <p>
              Не спать на стороне, с которой удалили зуб, первые 2-3 дня после удаления.
            </p>
            <p>
              Не перегреваться и не переохлаждаться первые 2-3 дня после удаления.
            </p>
            <p>
              Первую чистку зубов лучше отложить на следующее утро. Чистить зубы, избегая области 
ранки.
            </p>
            <p>
              Нельзя полоскать рот (в том числе после чистки зубов) и пользоваться ирригатором в 
первые трое суток. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEight">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
            <h3>Уход за полостью рта и брекетами после их установки</h3>
          </button>
        </h2>
        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Брекеты представляют собой специальную стоматологическую 
конструкцию, которая крепится на зубы. Она состоит из дуги и замков, дуга всегда 
изготавливается из металла, замки в клинике «Экодент» применяются 2-х видов: 
            </p>
            <ul>
              <li>металлические;</li>
              <li>керамические.</li>
            </ul>
            <p>
              Уход за брекетами после установки заключается в тщательном устранении 
налета и (по возможности) дезинфекции металлических элементов.
            </p>
            <p>
              В качестве необходимого минимума для ухода за брекетами потребуется:
            </p>
            <ul>
              <li>V-образная зубная щетка;</li>
              <li>монопучковая щетка;</li>
              <li>ирригатор;</li>
              <li>зубная паста;</li>
              <li>вощеная стоматологическая нить;</li>
              <li>флос-зубочистки, или межзубные ершики;</li>
              <li>карманное зеркальце.</li>
            </ul>
            <p>
              Лучше всего  приобрести готовый ортодонтический набор. Его удобно 
носить с собой, чтобы применять после каждого приема пищи даже вне дома. 
Ирригатор приобретается отдельно.
            </p>
            <p>
              Полноценный уход за брекет-системой начинается с чистки зубов, 
которую необходимо осуществлять после каждого приема пищи.
            </p>
            <p>
              Чистить нужно зубы и все доступные элементы брекет-системы. 
            </p>
            <p>
              Чистку следует выполнять тщательно, не менее 3-4 минут, при этом 
использовать специальные щетки, пасты и приспособления. Чистка в домашних 
условиях состоит из нескольких обязательных шагов:
            </p>
            <ul>
              <li>
                ротовую полость тщательно очищают, особенно в труднодоступных местах, 
с особой осторожностью действуя возле замков брекета, не делая резких движений 
и сильных надавливаний;
              </li>
              <li>
                очистку проводят как горизонтальными, так и вертикальными движениями 
(замки чистят только в вертикальном положении);
              </li>
              <li>
                для чистки используют специальную ортодонтическую щетку V-образной 
формы, а также монопучковую зубную щетку для качественной обработки 
межзубного пространства и ершик для очистки деталей системы и пространства 
между зубами;
              </li>
              <li>
                оптимальная длительность процедуры – около 10 минут.
              </li>
            </ul>
            <p>
              Зубную пасту применять с содержанием кальция и фтора, чтобы улучшить 
прочность и защитные свойства эмали зубов, особенно в местах соприкосновения с 
брекетами.
            </p>
            <p>
              Чистку зубов хорошо дополнять очисткой межзубного пространства с 
помощью зубной нити (флосса) и ирригатора.
            </p>
            <p>
              Заканчивать процедуры полосканием соленой водой или ополаскивателем, 
в составе которого не должно быть спирта и красителей (он же поможет удалить 
остатки пищи в тех случаях, когда нет возможности полноценной очистки полости 
рта).
            </p>
            <p>
              Очищать металлические части брекета следует специальной щеточкой или 
ершиком.
            </p>
            <p>
              Для контроля за стабильностью брекет-системы следует ежедневно 
проверять ее на отсутствие поломок.
            </p>
            <p>
              После установки брекетов не пропускайте назначенные даты приема у 
ортодонта.
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="accordion-item">
        <h2 class="accordion-header" id="headingNine">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            <h3>Сколько стоит делать импланты?</h3>
          </button>
        </h2>
        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Со стоимостью на услуги вы можете ознакомиться в соответствующем разделе нашего сайта. Чтобы ответить на вопрос, сколько будет стоить лечение ваших зубов, необходимо пройти консультативно-диагностический прием у врача-стоматолога-хирурга клиники. 
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
            <h3>Сколько стоит чистка зубов в клинике «Экодент»?</h3>
          </button>
        </h2>
        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              В клинике проводится  ультразвуковая чистка, воздушно-абразивная обработка зубов. В среднем стоимость профессиональной гигиены - от 2000 рублей. Чтобы узнать точную цену нужно пройти осмотр стоматолога клиники.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEleven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
            <h3>Что делать, если у меня повышенный рвотный рефлекс?</h3>
          </button>
        </h2>
        <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Рекомендуем за 40 минут до приема у стоматолога принимать противорвотные препараты, например церукал (имеются противопоказания).
            </p>
          </div>
        </div>
      </div> -->

    </div>
</template>

<script>
export default {
name: 'Akkord'
}
</script>

<style scoped>

.accordion {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* margin-bottom: 4vh; */
}

.accordion-item {
  background: #fff;
}

.accordion-button {
  width: 100%;
  background: #ceface;
  text-shadow: none;
}

button:hover {
  background: white;
  border-color: green;
  color: green;
  transition: all 0.4s ease;
  text-shadow: none;
}

h2 {
  font-size: 2vw;
}

h3 {
  color: green;
  margin-bottom: 0;
  font-size: 1.3vw;
  width: 38vw;
}

p {
  margin-top: 10px;
  min-width: 40vw;
  font-size: 1.1vw;
}

li {
  font-size: 1.1vw;
}

@media (max-width: 790px) {

  h2 {
    /* margin-top: 30px; */
    font-size: 30px;
  }

  h3 {
    font-size: 19px;
    width: 90%;
  }

  p {
    width: 100%;
    font-size: 17px;
  }

  button {
    width: 100%;
  }

  .accordion-item {
    border-width: 0;
  }

  .accordion-button {
    min-height: 70px;
  }

  li {
    font-size: 17px;
  }
    
}

</style>