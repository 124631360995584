<template>
    <button 
        type="button" 
        data-bs-toggle="modal" 
        data-bs-target="#exampleModal"
        class="fbn"
    >
        Заказать обратный звонок
    </button>
    <Modal />
</template>

<script>
import Modal from '@/components/Modal.vue'
export default {

    components: {
        Modal,
    }
}
</script>

<style scoped>

.fbn {
    position: fixed;
    z-index: 100;
    margin-top: 75vh;
    margin-left: 75vw;
    background: green;
    border: 0.1 solid;
    border-color: red;
    color: white;
    font-size: 1.5vw;
    height: 8vh;
    transition: all 0.4s ease;
    animation: bounce 2s ease infinite;

    opacity: 1;
}

@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
}

.fbn:hover {
    background: #fff;
    color: red;
    border-color: red;
    transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .fbn {
        opacity: 0;
    }

}

</style>