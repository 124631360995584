import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NavView from '../views/NavView.vue'
import AboutView from '../views/AboutView.vue'
import ServicesView from '../views/ServicesView.vue'
import PricesView from '../views/PricesView.vue'
import ContactsView from '../views/ContactsView.vue'
import TherapyView from '../views/TherapyView.vue'
import OrthopedicsView from '../views/OrthopedicsView.vue'
import SurgeryView from '../views/SurgeryView.vue'
import ImplantationView from '../views/ImplantationView.vue'
import OrthodonticsView from '../views/OrthodonticsView.vue'
import Hygiene_productsView from '../views/Hygiene_productsView.vue'
import QuestionView from '../views/QuestionView.vue'
import SaveView from '../views/SaveView.vue'
import ConclusionView from '../views/ConclusionView.vue'
import CertificateView from '../views/CertificateView.vue'
import StockView from '../views/StockView.vue'
import GuaranteesView from '../views/GuaranteesView.vue'
import DocView from '../views/DocView.vue'
import RulesView from '../views/RulesView.vue'
import ConfidView from '../views/ConfidView.vue'
import VacanciesView from '../views/VacanciesView.vue'

import NotFoundComponent from '../views/NotFoundComponent.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/nav',
    name: 'nav',
    component: NavView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/prices',
    name: 'prices',
    component: PricesView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/therapy',
    name: 'therapy',
    component: TherapyView
  },
  {
    path: '/orthopedics',
    name: 'orthopedics',
    component: OrthopedicsView
  },
  {
    path: '/surgery',
    name: 'surgery',
    component: SurgeryView
  },
  {
    path: '/implantation',
    name: 'implantation',
    component: ImplantationView
  },
  {
    path: '/orthodontics',
    name: 'orthodontics',
    component: OrthodonticsView
  },
  {
    path: '/hygiene_products',
    name: 'hygiene_products',
    component: Hygiene_productsView
  },
  {
    path: '/question',
    name: 'question',
    component: QuestionView
  },
  {
    path: '/save',
    name: 'save',
    component: SaveView
  },
  {
    path: '/conclusion',
    name: 'conclusion',
    component: ConclusionView
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: CertificateView
  },
  {
    path: '/stock',
    name: 'stock',
    component: StockView
  },
  {
    path: '/guarantees',
    name: 'guarantees',
    component: GuaranteesView
  },
  {
    path: '/doc',
    name: 'doc',
    component: DocView
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesView
  },
  {
    path: '/confid',
    name: 'confid',
    component: ConfidView
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: VacanciesView
  },
  
  {
    path: '/:pathMatch(.*)*', 
    name: 'notfound',
    component: NotFoundComponent
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { 
      top: 0, 
      behavior: 'smooth',
    }
  },
})

export default router
