<template>
    <Logo />
    <But />
    <div class="tt">
        <h1>Предметы и средства гигиены полости рта</h1>
        <div class="tm">
            <div>
                <p>
                    В нашей клинике Вы можете приобрести качественные средства гигиены полости рта. 
                </p>
                <ul>
                    <li>Воск ортодонтический Revyline</li>
                    <li>Зубная нить 50м Revyline PTFE (тефлон) Black</li>
                    <li>Зубная паста BioRepair</li>
                    <li>Зубная щетка детская CORLYSE</li>
                    <li>Межзубные ершики Revyline MIX</li>
                    <li>Набор зубных щеток Revyline, SM5000</li>
                    <li>Набор зубных щеток Revyline, SM6000</li>
                    <li>Набор ортодонтический Revyline</li>
                    <li>Ирригатор Revyline </li>
                </ul>
                <p>
                    Ознакомиться со всем ассортиментом товаров вы можете в клинике "ЭКОДЕНТ"  либо позвонив по контактному телефону клиники.
                </p>
                <a href="tel:+79530197374"><button>Записаться на прием</button></a>
            </div>
            <a-carousel autoplay arrows effect="fade">
                <template #prevArrow>
                    <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                        <left-circle-outlined />
                    </div>
                </template>
                <template #nextArrow>
                    <div class="custom-slick-arrow" style="right: 10px">
                        <right-circle-outlined />
                    </div>
                </template>
                    <div><img class='ii' alt="" src="../assets/pr3.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr2.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr1.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr4.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr5.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr6.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr7.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/pr8.jpg" /></div>
            </a-carousel>
        </div>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
    name: "TherapyView",
    components: {
        Logo,
        Footer,
        ACarousel: Carousel,
        LeftCircleOutlined, 
        RightCircleOutlined,
        But,
    }
}
</script>

<style scoped>
.tt {
    padding: 5vh 3.5vw;
    min-height: 100vh;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

p {
    font-size: 1.5vw;
    width: 43vw;
}

ul{
    list-style: none;
    padding: 0; 
    margin: 0;
}

li {
    width: 43vw;
    font-size: 1.2vw;
}

ul > li{
    padding-left: 5%;
    position: relative;
}

ul > li:before{
    content: "\2714";
    position: absolute; top: 0; left: 0;
}

.tm {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

button {
  margin-top: 5vh;
  margin-bottom: 5vh;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

.ii {
    width: 100%;
    min-height: 30vh;
    overflow: hidden;
    object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
    text-align: center;
    width: 100%;
    min-height: 30vh;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3vw;
  height: 25px;
  font-size: 3vw;
  color: red;
  background-color: rgba(251, 252, 253, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

    .tt {
        padding: 5em 1em;
    }

    .tm {
        margin-top: 0;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    h1 {
        font-size: 24px;
    }

    p {
        font-size: 17px;
        width: 100%;
    }

    li {
        font-size: 15px;
        width: 100%;
    }

    button {
        width: 100%;
        font-size: 19px;

        opacity: 1;
    }

    .ii {
        width: 100%;
    }
    
    .ant-carousel :deep(.slick-slide) {
        margin-top: 70px;
    }

    .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
        width: 9vw;
        font-size: 9vw;
        margin-top: 20px;
    }
    
}
</style>