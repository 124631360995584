<template>
  <Logo />
  <But />
  <div class="ser">
    <div class="ss">
      <h1>Услуги</h1>
      <Servis />
      <div class="dbz">
        <a href="tel:+79530197374"><button>Записаться на прием</button></a>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Servis from '@/components/Servis.vue'
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
  name: 'ServicesView',
  components: {
    Logo,
    Servis,
    Footer,
    But,
  }
}
</script>

<style scoped>
.ser {
  padding: 5vh 3.5vw;
  background-image: url(../assets/fon2.jpg);
  background-size: cover;
}

.ss {
  min-height: 100vh;
  margin: 0 auto;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
  text-align: center;
  /* animation: animate 3s linear infinite;
  background-size: 80%;
  text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green; */
  
}
/* @keyframes animate{
  50%{color: white;}
  50%{color: orange; text-shadow: 0 0 5px blue, 0 0 10px green;}
} */

p {
  margin-top: 50px;
  
}

.dbz {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

button {
  margin-top: 0;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

@media (max-width: 790px) {

  .ser {
    padding: 5em 1em;
  }

  .ss {
    width: 100%;
  }

  h1 {
    font-size: 24px;
  }

  .dbz {
    width: 100%;
  }

  button, a {
    width: 100%;
    font-size: 19px;

    opacity: 1;
  }

}
</style>