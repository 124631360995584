<template>
  <Logo />
  <Vid />
  <Par />
  <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Vid from '@/components/Vid.vue'
import Par from '@/components/Par.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Logo,
    Vid,
    Par,
    Footer
  }
}
</script>
