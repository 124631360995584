<template>
    <div class="ff">
        <div class="int">
            <div>
                <p class="p1">
                    Общество с ограниченной ответственностью «ЭКОДЕНТ» (ООО «ЭКОДЕНТ»)
                </p>
                <p><router-link class="rl" to="/contacts">г. Чебоксары, ул. Николая Рождественского, 1</router-link></p>
                <p>
                    <router-link class="rl" to="/confid">
                        Политика конфиденциальности
                    </router-link>
                </p>
                <p class="pf">
                    График работы: <br>
                    ПН-ПТ 08:00 - 20:00, СБ 09:00 - 15:00, ВС выходной <br>
                </p>
            </div>
            <div>
                <p class="pvo">
                    Прием наших врачей осуществляется по предварительной записи
                </p>
                <p>
                    Записаться на прием к специалисту стоматологии Вы можете: 
                </p>
                <ul>
                    <li>непосредственно придя в клинику;</li>
                    <li>с помощью кликабельной кнопки данного сайта «Записаться на прием»;</li>
                    <li class="ltw">позвонив по контактному телефону 
                        <a class="tel" href="tel:+79530197374">+7 (953) 019-73-74</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style scoped>
.ff {
    width: 100%;
    height: 30vh;
    background-color: forestgreen;
}

.int {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr)); 
    justify-items: center;  
}

.p1 {
    margin-top: 4vh;
}

.pvo {
    margin-top: 4vh;
}

p {
    font-size: 1.1vw;
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
}

li {
    color: #fff;
    font-size: 1vw;
}

.rl {
    font-size: 1.1vw;
    margin: 0 0 0 0;
}

.tel {
    font-size: 1.1vw;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.rl:hover, 
.tel:hover {
    color: red;
}

a {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}


@media (max-width: 790px) {
    .ff {
        min-height: 670px;
    }

    .int {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); 
        justify-items: start;  
    }

    .i1,
    .i2 {
        display: block;
        margin-top: 20px;
    }

    .rl {
        font-size: 19px;
    }

    .tel {
        font-size: 25px;
        margin-left: 0px;
    }

    p {
        font-size: 19px;
        padding: 0px;
        margin-left: 14px;
    }

    li {
        font-size: 14px;
    }

    .pf {
        font-size: 17px;
        width: 320px;
    }

    a {
        font-size: 24px;
    }

    .p1 {
        width: 300px;
    }

    .ltw {
        width: 260px;
    }

}
</style>