<template>
    <div class="vi">
        <div class="car">
            <div><img class='ie' alt="" src="../assets/ec2.jpg" /></div>
            <!-- <a-carousel autoplay arrows effect="fade">
                <template #prevArrow>
                    <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                        <left-circle-outlined />
                    </div>
                </template>
                <template #nextArrow>
                    <div class="custom-slick-arrow" style="right: 10px">
                        <right-circle-outlined />
                    </div>
                </template>
                    <div><img class='ii' alt="" src="../assets/ec1.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/ec2.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/viv.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/e2.jpg" /></div>
                    <div><img class='ii' alt="" src="../assets/e1.jpg" /></div>
            </a-carousel> -->
        </div>
        <div class="pri">
            <h1>Добро пожаловать <br> в стоматологическую клинику <br> "ЭКОДЕНТ"</h1>
            <router-link to="/contacts">
                <h3>г. Чебоксары, ул. Николая Рождественского, 1</h3>
            </router-link>
            <h3>ПН-ПТ 08:00 - 20:00, <br>
                СБ 09:00 - 15:00, <br> 
                ВС выходной
            </h3>
            <p>
                Прием врачей осуществляется по предварительной записи, актуальный график приема можно уточнить у администратора
            </p>
            <h1
                v-if="small"
            ><a class="tel" href="tel:+79530197374">+7 (953) 019-73-74</a></h1>
            <button 
                type="button" 
                data-bs-toggle="modal" 
                data-bs-target="#exampleModal"
            >
                Заказать обратный звонок
            </button>
            <a class="tel" href="tel:+79530197374">
                <button
                    class="btz"
                    v-if="small"
                >
                    Позвонить
                </button>
            </a>
        </div>
        <Modal />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
// import { Carousel } from 'ant-design-vue';
// import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';

export default {
    name: 'Vid',
    components: {
        Modal,
        // ACarousel: Carousel,
        // LeftCircleOutlined, 
        // RightCircleOutlined,
    },
    data: () => ({
        small: false
    }),
    created() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
        this.small = window.innerWidth <= 790;
        }
    },
}
</script>

<style scoped>
.vi {
    min-height: 50vh;
    display: flex;
}

.car {
    width: 100%;
}

.pri {
    position: relative;
    margin: 22vh 0 0 -89.5%;
    color: #fff;
    z-index: 2;   
}

.tel {
    color: #fff;
    text-decoration: none;
}

button {
    margin-top: 30px;
    background: green;
    border: 0.1 solid;
    border-color: red;
    color: white;
    font-size: 1.5vw;
    height: 8vh;
    transition: all 0.4s ease;
    animation: bounce 2s ease infinite;
}
@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
}

button:hover {
    background: #fff;
    color: red;
    border-color: red;
    transition: all 0.4s ease;
}

h1,
h3 {
    color: #fff;
    font-size: 2.1vw;
}

p {
    font-size: 1.1vw;
}

h1,
h3,
a,
p {
    text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green;
}

.ie {
    width: 100%;
}

/* .ii {
    width: 100%;
    min-height: 10vh;
    overflow: hidden;
    object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
    text-align: center;
    width: 100%;
    min-height: 10vh;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 3vw;
  height: 25px;
  font-size: 3vw;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
} */

@media (max-width: 790px) {

    .vi {
        margin-right: 0;
        height: 800px;
        display: block;
    }

    .vi:after{
        max-height: 0px;
    }

    .pri {
        margin-left: 0px;
        margin-top: 0px;
        padding: 15px;
        background-image: url(../assets/fon2.jpg);
        background-size: cover;
    }

    h1 {
        font-size: 25px;
        color: green;
        text-shadow: none;
    }

    .tel {
        font-size: 25px;
        /* font-weight: bold; */
        color: #000;
        text-shadow: none;
    }

    h3 {
        font-size: 19px;
        color: darkgreen;
        text-shadow: none;
    }

    p {
        font-size: 19px;
        color: darkgreen;
        text-shadow: none;
    }

    button {
        margin-top: 15px;
        background-color: rgb(2, 247, 2);
        border-color: red;
        color: #fff;
        width: 100%;
        height: 60px;
        font-size: 21px;
    }

    .btz {
        background-color: green;
        border-color: red;
        color: #fff;
    }

    .btz:hover {
        background: rgb(2, 247, 2);
        color: #fff;
    }

    /* .ii {
        width: 100%;
    }
    
    .ant-carousel :deep(.slick-slide) {
        margin-top: 70px;
    }

    .ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
        width: 9vw;
        font-size: 9vw;
        margin-top: 20px;
    } */
   
}

</style>