<template>
  <div class="modal" id="exampleModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Заполните пожалуйста форму</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5>Мы ответим Вам при первой же возможности</h5>
          <form ref="form" @submit.prevent="sendEmail">    
            <input 
              required
              type="text" 
              placeholder="Имя"
              name="user_name"
            >
            <input 
              required
              type="number" 
              placeholder="Телефон"
              name="user_phone"
            >
            <textarea 
              type="text" 
              placeholder="Добавьте сообщение..."
              name="message"
            ></textarea>
            <input 
              class="check"
              type="checkbox"
              checked 
              id="sog" 
              value="sog" 
              name="checkedSog" 
            />
            <label 
              for="sog"
            >
              Нажимая на кнопку «Отправить», я даю согласие
              <router-link class="rl" to="/confid">
                <button type="button" class="btob" data-bs-dismiss="modal">
                  на обработку персональных данных
                </button>
              </router-link>
            </label>
            
            <div class="bt">
              <input 
                class="bts"
                type="submit" 
                value="Отправить"
              >
            </div>
            <div class="vis" 
              v-show="visible"
            >
              <p>
                Сообщение успешно отправлено, <br>
                скоро мы Вам перезвоним.
              </p> 
              <button 
                type="button" 
                class="btn btn-outline-light" 
                data-bs-dismiss="modal"
              >
                Закрыть
              </button>
            </div>  
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: "Modal",
  data: () => ({     
    visible: false,   
  }),
  methods: {
    sendEmail(event) {
      emailjs.sendForm('service_9zwn7gj', 'template_osvf6in', this.$refs.form, 'Y8oiq7KVgw634u9xD')
        .then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        });
        event.target.reset();
        this.visible = true;
        setTimeout(() => this.visible = false, 10000);
    }
  }
}
</script>

<style scoped>

.modal-content {
  background-color: forestgreen;
  min-height: 600px;
  margin-top: 10px;
}

.modal-title {
  color: #FFF;
}

h5 {
  color: white;
}

input {
  margin-top: 10px;
  border: #BB613A;
  width: 100%;
  height: 50px;
  padding: 20px;
}

textarea {
  margin-top: 10px;
  border: #BB613A;
  width: 100%;
  height: 100px;
  padding: 20px;
}

.check {
  width: 20px;
  margin: 0 0 0 0;
}

label {
  vertical-align: top;
  margin: -40px 30px;
  color: #fff;
}

.btob {
  background: none;
  border: none;
  text-decoration: underline;
  color: white;
}

.btob:hover {
  color: blue;
}

.bt {
  margin-top: 10px;
}

.bts {
  background: red;
  border: red;
  color: #fff;
  width: 100%;
  height: 50px;
  font-size: 20px;
  margin-top: 0;
  padding: 0;
}

.vis {
  margin-top: 20px;
  font-size: 17px;
  color: green;
}

.btn {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

p {
  color: red;
  text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green;
}

@media (max-width: 790px) {

  label {
    vertical-align: top;
    margin: -40px 30px;
    color: #fff;
  }

}

</style>