<template>
    <div class="nn">
        <div class="hh">
            <a @click="$router.go(-1)">             
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="40" 
                        height="40" 
                        fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                    </svg>
                </button>
            </a>
        </div>
        <nav>
            <ul>
                <li><router-link to="/">Главная</router-link></li>
                <li><router-link to="/about">О клинике</router-link></li>
                <router-link to="/doc">Врачи</router-link>
                <router-link to="/vacancies">Вакансии</router-link>
                <li><router-link to="/services">Услуги</router-link></li>
                <li><router-link to="/prices">Цены</router-link></li>
                <li><router-link to="/stock">Акции</router-link></li>
                <li><router-link to="/contacts">Контакты</router-link></li>
            </ul>
        </nav>
        <div class="dvk">
            <a href="https://vk.com/ecodent21" target="_blank" rel="noopener">
                <img class='vk' alt="" src="../assets/vk.jpg"/>
            </a>
            <a class="btn_telegram_share" href="https://t.me/+6meMgc7Np8VmZDYy" target="_blank" rel="noopener">
                <img class='vk' alt="" src="../assets/telega.png"/>
            </a>
        </div>
        <br>
    </div>
</template>

<script>
export default {
    name: 'Offcanvas'
}
</script>

<style scoped>

.nn {    
    min-height: 100vh;

    background-color: green;
    /* background-image: url(../assets/fon2.jpg);
    background-size: cover; */
}

.hh {
    display: flex;
    justify-content: end;
}

button {
    margin: 15px 7px;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    color: #fff;
}

.nav {
    
}

nav a {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 35px;
}

nav a:hover {
    color: #FF9C27;
}

nav a.router-link-exact-active {
    color: #EAB124;
}

ul {
    margin-left: 0; 
    padding-left: 0; 
}

li {
    list-style-type: none; 
}

.dvk {
    display: flex;
    justify-content: center;
}

.vk {
  width: 15vw;
  margin-right: 10px;
  margin-left: 10px;
}

</style>