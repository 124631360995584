<template>
    <Logo />
    <But />
    <div class="tt">
        <div class="tm">
            <img src="../assets/ako.jpg" alt="">
        </div>
        <div class="tm">
            <a href="tel:+79530197374"><button>Записаться на прием</button></a>
        </div>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
    name: "TherapyView",
    components: {
        Logo,
        Footer,
        But,
    }
}
</script>

<style scoped>
.tt {
    min-height: 100vh;
    padding: 5vh 3.5vw;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

p {
  font-size: 1.3vw;
}

.tm {
    display: flex;
    justify-content: center;
}

img {
    width: 60vw;
}

button {
  margin-top: 50px;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  margin-bottom: 20px;
 
    opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

@media (max-width: 790px) {

    .tt {
        padding: 5em 1em;
    }

    h1 {
        margin-left: 5px;
        font-size: 24px;
    }

    p {
        font-size: 19px;
    }

    img {
        width: 100%;
    }

    button,
    a {
        width: 100%;
        font-size: 19px;
        opacity: 1;
    }
    
}
</style>