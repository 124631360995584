<template>
  <div class="log">
    <router-link 
      class="hh"
      to="/"
    >
      <img class='ll' alt="" src="../assets/ll2.png"/>
    </router-link>
    <div class="head">
      <header>
        <router-link 
          class="hh"
          to="/"
        >
          <h1>ЭКОДЕНТ</h1>
          <h5>стоматологическая клиника</h5>
        </router-link>
      </header> 
      <router-link to="/nav"> 
        <button 
          class="bm" 
          v-if="small"
        >
          <svg xmlns="http://www.w3.org/2000/svg" 
            width="50" 
            height="50" 
            fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
      </router-link> 
      <nav v-if="!small">
        <router-link to="/">Главная</router-link>
        <router-link to="/about">О клинике</router-link>
        <router-link to="/doc">Врачи</router-link>
        <router-link to="/vacancies">Вакансии</router-link>
        <router-link to="/services">Услуги</router-link>
        <router-link to="/prices">Цены</router-link>
        <router-link to="/stock">Акции</router-link>
        <router-link to="/contacts">Контакты</router-link>
        <a href="https://vk.com/ecodent21" target="_blank" rel="noopener">
          <img class='vk' alt="" src="../assets/vk.jpg"/>
        </a>
        <a class="btn_telegram_share" href="https://t.me/+6meMgc7Np8VmZDYy" target="_blank" rel="noopener">
          <img class='vk' alt="" src="../assets/telega.png"/>
        </a>
      </nav>
      <a v-if="!small" class="tel" href="tel:+79530197374">+7 (953) 019-73-74</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data: () => ({
    small: false
  }),
  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.small = window.innerWidth <= 790;
    }
  },
};
</script>

<style scoped>

.log {
  display: flex;
  position: sticky;
  top: 0;
  min-height: 12vh;
  z-index: 100;
  background-color: forestgreen;
}  

.head {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.hh {
  text-decoration: none;
}

.ll {
  width: 6vw;
  margin-left: 2.8vw;
}

.bm {
  background: none;
  color: #fff;
  border: #fff;
}

nav {
 
}

nav a {
  font-size: 1.1vw;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  margin-left: 1.5vw;
}

nav a:hover {
  color: red;
}

nav a.router-link-exact-active {
  color: red;
}

.vk {
  width: 3vw;
  transition: all 0.4s ease;
}

.vk:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

.tel {
  margin-right: 2.5vw;
  text-decoration: none;
  color: #fff;
  font-size: 1.5vw;
  font-weight: bold;
}

.tel:hover {
  color: red;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 2.5vw;
}

h1 {
  margin-top: 2vh;
  font-family: itc-avant-garde-gothic-pro,sans-serif;
  font-weight: bold;
  font-style: normal;
  color: white;

  font-size: 2vw;

  line-height: 0.01vw;
  font-feature-settings: "ss01" 1, "ss03" 1, "kern" 1, "dlig" 1;
}

h5 {
  margin: 0 0 0 3px;
  color: #fff;
  font-weight: bold;
  font-size: 1.1vw;
}

@media (max-width: 790px) {

  h1 {
    font-size: 8vw;
    margin-top: 17px;
  }

  h5 {
    font-size: 5vw;
  }

  .ll {
    width: 85px;
    height: 100%;
  }

}
</style>