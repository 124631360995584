<template>
    <div>
        <div class="dkv">
            <div class="dd">
                <router-link to="/therapy">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/hir.jpg" alt="">
                        <h3 class="title">Терапия. <br>
                            Профессиональная гигиена
                        </h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/orthopedics">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/ort0.jpg" alt="">
                        <h3 class="title">Ортопедия. Протезирование</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/surgery">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/hir2.jpg" alt="">
                        <h3 class="title">Хирургия. Имплантация</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div> 
                </router-link> 
            </div>          
        </div>
        <div class="dks">
            <div class="dd">
                <router-link to="/implantation">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/dia2.jpg" alt="">
                        <h3 class="title">Диагностика</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/orthodontics">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/orto2.jpg" alt="">
                        <h3 class="title">Ортодонтия. Исправление прикуса</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/hygiene_products">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/gig.jpg" alt="">
                        <h3 class="title">Предметы и средства гигиены полости рта</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Servis'
}
</script>

<style scoped>

.dkv {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));
    /* margin-top: 2vh; */
}

.dks {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));
}

.dd {
    margin-bottom: 1.5vh;
}

.ikv {
    width: 25vw;
    border-radius: 25px;
}

.hover-effect-btn {
  position: relative;
  width: 25vw;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Настройка цвета и прозрачности для блока с заголовком */
  transition: 0.5s ease;
  border-radius: 25px;
}
.hover-effect-btn:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3); /* Настройка цвета и прозрачности для блока с заголовком при наведении */
}
.title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 60%; /* Отступ сверху */
  font-size: 1.4vw; /* Размер текста заголовка */
  text-align: center;
  text-transform: uppercase; /* Заглавные буквы */
  color: white; /* Цвет заголовка */
  z-index: 1;
  transition: top .5s ease;
  text-shadow: 2px 2px 4px black, 0 0 1em green, 0 0 0.2em green;
}
.hover-effect-btn:hover .title {
  top: 40%; /* Отступ сверху при наведении */
}
.button {
  position: absolute;
  width: 100%;
  left: 0;
  top: 65%; /* Отступ сверху */
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
  color: #fff;
  border: #fff;
  /* transition: all 0.4s ease; */
}
.button a {
  padding: 15px 40px; /* Отступ */
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  background: none; /* Цвет кнопки */
  color: white; /* Цвет текста */
  border: 2px solid white; /* Ширина, стиль и цвет границы */
  z-index: 1; 
}
.hover-effect-btn:hover .button {
  opacity: 1;
}

@media (max-width: 790px) {

    .hover-effect-btn {
        margin-bottom: 10px;
    }

    .dks {
        margin-top: 0px;        
    }

    .dkv,
    .dks {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }

    .ikv {
        width: 100%;        
    }

    .title {
        font-size: 25px;
    }

    .hover-effect-btn {
        width: 100%;
    }

}
</style>