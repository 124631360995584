<template>
    <div>       
        <div class="dd">
            <div class="doc">
                <div data-aos="fade-right" data-aos-duration="3000">
                    <div class="imvr">
                        <img class="vr" src="../assets/sev.jpg" alt="">
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                    <div class="td">
                        <h1>Скворцова Елена Валентиновна</h1>
                        <h4>Главный врач, <br>
                            врач-стоматолог-терапевт клиники «ЭКОДЕНТ»
                        </h4>
                        <ul>
                            <li>Для команды «ЭКОДЕНТ» стоматология – это Искусство.</li>
                            <li>Мы творчески подходим к решению любой зубной проблемы.</li>
                            <li>В клинике вам окажут стоматологическую помощь высококвалифицированные специалисты.</li>
                            <li>Мы используем современное оборудование, технологии и препараты.</li>
                            <li>Подберем оптимальную стратегию.</li>
                            <li>Стоматология «ЭКОДЕНТ» - это превосходное качество по умеренной цене.</li>
                            <li>Нам важен каждый пациент. Здоровые зубы, красивая улыбка!</li>
                            <li>Добро пожаловать!</li>
                        </ul>
                        <p class="dobp">
                            Прием граждан главным врачом ООО "ЭКОДЕНТ" по вопросам качества оказания медицинской помощи: <br>
                            Понедельник с 15:00 до 17:00 <br>
                            тел. 8-953-019-73-74 <br>
                            e-mail: ecodent21@mail.ru <br>
                            Прием осуществляется по предварительной записи
                        </p>
                        <a href="tel:+79530197374"><button>Записаться на прием</button></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="tt">
            <div class="txt">
                <div class="us">
                    <h1 class="uh">Услуги</h1>
                </div>
                <div class="pru"></div>
                <Servis />
                <div class="dbz">
                    <a href="tel:+79530197374"><button class="bz">Записаться на прием</button></a>
                </div>
            </div>
        </div>
        <div class="bg par2">
            <h2 class="bgh">Вопрос-ответ</h2>
            <div class="btl">
                <router-link to="/question"><button class="bt2">Задайте вопрос</button></router-link>
            </div>
        </div>
        <div class="tt">
            <div class="txt">
                <div class="us">
                    <h1 class="uh">Отзывы</h1>
                </div>
                <div class="not">
                    <button 
                        type="button"
                        class="bot"
                        data-bs-toggle="modal" 
                        data-bs-target="#exampleModal"
                    >
                        Написать отзыв
                    </button>
                </div>
                <div class="ot">
                    <div class="otz">
                        <h3 class="oth">Лилия Сергеева</h3>
                        <p class="otp">
                            "Раз в полгода стараюсь для профилактики ходить в стоматологию. По совету друзей пошла в зубную клинику Экодент.
Была впечатлена комфортом, отзывчивостью персонала и работой команды профессионалов. Многих врачей знала по другим клиникам- спасибо Экоденту, что собрали их в одном месте.
Записала для себя - терапевты Скворцова, Ремнева, пародонтолог Сперанская, ортопеды Спода и Чернов, хирурги Яковлев и Эвриков, ортодонт Анисимова.
Искренне рекомендую!"
                        </p>
                    </div>
                    <div class="otz">
                        <h3 class="oth">Светлана Иванова</h3>
                        <p class="otp">
                            "В стоматологическую клинику Экодент пришла по рекомендации знакомых. Положительные отзывы о работе специалистов клиники полностью оправдались. Здесь я вылечила два зуба, прошла чистку зубов. В ближайшее время планирую имплантацию 2-х зубов. Доверю это специалистам Экодент. Особая благодарность Ремневой Ольге Гурьевне и Споде Андрею Михайловичу.
Послушав мои отзывы о специалистах клиники, родители доверили здоровье своих зубов врачам Экодент. Теперь Это наша семейная стоматология!"
                        </p>
                    </div>
                    <div class="otz">
                        <h3 class="oth">Светлана Андреева</h3>
                        <p class="otp">
                            "Сегодня в клинике ECODENT была художественная реставрация центрального зуба. Результатом работы очень довольна, я в восторге. Спасибо Елене Валентиновна, она настоящий мастер своего дела. Огромное ей спасибо за её работу, теперь могу широко улыбаться. Всем рекомендую"
                        </p>
                    </div>
                    <div class="otz">
                        <h3 class="oth">Татьяна Александрова</h3>
                        <p class="otp">
                            "Сегодня была в клинике и осталась в восторге! До этого посетила более 5 медучреждений (частные и государственные). Но нигде мне не смогли поставить верный диагноз… А в «Экодент» действительно работают профессионалы, за что им очень благодарна. После первичного осмотра определили проблему и сразу же назначили операцию. К тому же, стоимость почти в два раза меньше, по сравнению с другими клиниками. Всем советую!!"
                        </p>
                    </div>
                    <div class="otz">
                        <h3 class="oth">Александр С.</h3>
                        <p class="otp">
                            "Протезировался в клинике "Экодегт", остался очень доволен результптом, хотело ба добааить о сервисе, все прекоамно, учтиво, добросердечно, вежливо. Рекомендую"
                        </p>
                    </div>
                    <div class="otz">
                        <h3 class="oth">Ольга Р.</h3>
                        <p class="otp">
                            "Лечились в "Экодент" в январе всей семьей. Клиника чудесная - высококлассные специалисты, самое совершенное оборудование, уютные помещения, умеренные цены. Отдельное спасибо нашему доктору Ремневой Ольге Гурьевне, лечимся у нее уже 20 лет - профессионал высокого класса, очень внимательная и чуткая"
                        </p>
                    </div>
                </div>
            </div>
        </div>        
        <div class="bg par3">
            <h2 class="bgh">Как сберечь свои зубы</h2>
            <div class="btl">
                <router-link to="/save"><button class="bt2">Узнать подробности</button></router-link>
            </div>
        </div>
        <div class="tt">
            <div class="txt">
                <div class="us">
                    <h1 class="uh">Информация для пациентов</h1>
                </div>
                <div class="in">
                    <div class="inf">
                        <div class="inp">
                            <ul>
                                <li>
                                    <a href="http://pravo.gov.ru/proxy/ips/?searchres=&bpas=cd00000&intelsearch=%E7%E
0%EA%EE%ED+%EE+%E7%E0%F9%E8%F2%E5+%EF%F0%E0%E2+%EF
%EE%F2%F0%E5%E1%E8%F2%E5%EB%E5%E9&sort=-1"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Закон Российской Федерации от 07.02.1992 N 2300-1 «О защите прав потребителей» 
                                    </a> 
                                </li>
                                <li>
                                    <a href="http://publication.pravo.gov.ru/document/0001202305120025"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Постановление Правительства РФ от 11 мая 2023 г. N 736 «Об утверждении Правил предоставления медицинскими организациями платных медицинских услуг, внесении изменений в некоторые акты Правительства Российской Федерации и признании утратившим силу постановления Правительства Российской Федерации от 4 октября 2012 г. N 1006»
                                    </a> 
                                </li>
                                <li>
                                    <a href="https://medicin.cap.ru/action/obespechenie-deyateljnosti/regionaljnie-programmi "            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Информация о Программе государственных гарантий бесплатного оказания гражданам медицинской помощи
                                    </a> 
                                </li>
                                <li>
                                    <router-link class="tr" to="/guarantees">
                                        Контакты контролирующих органов
                                    </router-link>
                                </li>
                                <li>
                                    <a href="https://cloud.mail.ru/public/uBJc/aFyWcwvQ8"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Выписка из реестра — лицензия
                                    </a> 
                                </li>
                                <li>
                                    <router-link class="tr" to="/certificate">Свидетельство о постановке на налоговый учет</router-link>
                                </li>
                                <li>
                                    <router-link class="tr" to="/conclusion">Санитарно-эпидемиологическое заключение</router-link> 
                                </li>  
                                <li>
                                    <router-link class="tr" to="/rules">
                                        Правила подготовки к приему стоматолога, диагностическим исследованиям, рентгенологическому обследованию (прицельному снимку), наличие противопоказаний для посещения стоматолога
                                    </router-link>
                                </li>
                                <li>
                                    <a href="https://www.consultant.ru/document/cons_doc_LAW_61801/"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Федеральный закон Российской Федерации от 27.07.2006 N 152-ФЗ «О персональных данных» 
                                    </a> 
                                </li>
                                <p class="pli">
                                    Информацию о стандартах медицинской помощи и клинических рекомендациях, на основании которых оказываются медицинские услуги, можно получить, пройдя по следующим ссылкам:
                                    <ul>
                                        <li>
                                            <a href="http://www.pravo.gov.ru/"            
                                                class="tr"
                                                target="_blank" 
                                                rel="noopener"
                                            >
                                                Официальный интернет-портал правовой информации
                                            </a> 
                                        </li>
                                        <li>
                                            <a href="https://cr.minzdrav.gov.ru/"            
                                                class="tr"
                                                target="_blank" 
                                                rel="noopener"
                                            >
                                                Рубрикатор клинических рекомендаций Минздрава России
                                            </a> 
                                        </li>
                                    </ul>
                                </p>
                                <li>
                                    <a href="https://cloud.mail.ru/public/d1Yw/NkwXE869B"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Выписка из ЕГРЮЛ
                                    </a> 
                                </li>
                                <li>
                                    <a href="https://cloud.mail.ru/public/mBZJ/bqZKH41Mf"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Положение об установлении гарантийного срока и срока службы при оказании стоматологической помощи в ООО «ЭКОДЕНТ»
                                    </a> 
                                </li>
                                <li>
                                    <a href="https://cloud.mail.ru/public/LnzJ/Qw3zuTzpy"            
                                        class="tr"
                                        target="_blank" 
                                        rel="noopener"
                                    >
                                        Методы оказания медицинской помощи, связанные с ними риски, виды медицинского вмешательства, их последствия и ожидаемые результаты оказания медицинской помощи
                                    </a> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Servis from '@/components/Servis.vue'

export default {
    name: 'Par',
    components: {
        Servis,
    }
}
</script>

<style scoped>

.dd {
    padding: 5vh 3.5vw;
    min-height: 60vh;
}

.doc {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
    margin: 0 auto;
}

.imvr {
    display: flex;
    justify-content: end;
}

.vr {
    width: 30vw;
    border-radius: 25px;
    background-size: cover;
    opacity: 1;
    margin-right: 7vw;
}

.td {
    width: 40vw;
    margin-top: 15vh;
}

h1 {
    text-transform: uppercase;
    color: #333333;
    font-size: 2vw;
}

h3 {
    text-transform: uppercase;
    color: #333333;
    font-size: 1.5vw;
}

h4 {
    font-size: 1.5vw;
}

h2 {
    font-size: 3vw;
    color: #fff;
}

ul{
    list-style: none;
    padding: 0; 
    margin: 0;
}

li, .pli {
    font-size: 1.1vw;
    color: #000;
}

ul > li{
    padding-left: 20px;
    position: relative;
}

ul > li:before{
    content: "\2714";
    position: absolute; top: 0; left: -1vw;
    /* color: green; */
}

.dobp {
    margin-top: 4vh;
    font-size: 1vw;
    line-height: 4vh;
}

.bg {
    min-height: 100vh;
    display: grid;
    place-content: center;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.tt {
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
    min-height: 30vh; 
}

.txt {
    padding: 5vh 3.5vw;
}

/* .par1 {
    background-image: url(../assets/1.jpg);
} */

.par2 {
    background-image: url(../assets/vo1.jpg);
}

.par3 {
    background-image: url(../assets/ksz1.jpg);
    background-position-y: top;
}

p {
    font-size: 1.5vw;
    line-height: 25px;
}

button {
    margin-top: 35px;
    background: none;
    border: 0.1 solid;
    border-color: red;
    color: #333333;
    font-size: 1.5vw;
    min-width: 250px;
    min-height: 50px;
    transition: all 0.4s ease;
    
    opacity: 0;
}

button:hover {
    background: green;
    border-color: green;
    color: white;
    transition: all 0.4s ease;
}

.dbz {
    display: flex;
    justify-content: center;
    margin-top: 6vh;
}

.bz {
    margin-top: 0;
    background: white;
    border: 0.1 solid;
    border-color: red;
    color: green;
    font-size: 1.5vw;
    /* width: 250px; */
    transition: all 0.4s ease;
    /* text-shadow: 1px 1px 2px black, 0 0 1em green, 0 0 0.2em green; */
    margin-bottom: 4vh;
}

.bz:hover {
    background: green;
    border-color: green;
    color: white;
    transition: all 0.4s ease;
    /* text-shadow: none; */
}

.btl {
    display: flex;
    justify-content: center;
}

.bt2 {
    margin: 0;
    background: green;
    border: 0.1 solid #fff;
    border-color: red;
    color: #fff;
    font-size: 2vw;
    /* width: 250px;
    height: 50px; */
    transition: all 0.4s ease;

    opacity: 1;
}

.bt2:hover {
    background: #fff;
    color: red;
    border-color: red;
    transition: all 0.4s ease;
}

.us {
    text-align: center;
}

.uh {
    position: relative;
    color: forestgreen;
    text-transform: uppercase;
    font-size: 2.5vw;
    font-weight: bold;
    letter-spacing: 4px;
    margin-bottom: 0;
}

.pru {
    height: 6vh;
}

.not {
    text-align: center;
}

.bot {
    margin-top: 20px;
    background: green;
    border: 0.1 solid;
    border-color: red;
    color: white;
    font-size: 1.5vw;
    transition: all 0.4s ease;

    opacity: 1;
}

.bot:hover {
    background: white;
    border-color: red;
    color: red;
    transition: all 0.4s ease;
}

.ot {
    margin-top: 5vh;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(30vw, 1fr));
}

.otz {
    width: 30vw;
    background-image: url(../assets/ofs.jpg);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    margin-bottom: 1.5vh;
}

.oth {
    margin: 3vh 3vw 3vh 3vw;
    font-size: 1.5vw;
}

.otp {
    margin: 0 3vw 3vh 3vw ;
    font-style: italic;
    font-size: 1.1vw;
    line-height: 4vh;
}

.in {
    margin-top: 3vh;
    display: flex;
    justify-content: center;
}

.inf {
    width: 91vw;
    min-height: 100vh;
    background-image: url(../assets/stek.png);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    margin-bottom: 30px;
}

.inp {
    padding: 3vw;
    color: #fff;
}

.tr {
    color: #000;
    font-size: 1.2vw;
    line-height: 5vh;
}

.tr:hover {
    color: red;
}

@media (max-width: 790px) {

    .dd {
        padding: 0em 1em;
    }

    .vr {
        width: 100%;
        margin-left: 0px;
        margin-right: 0;
    }

    .dobp {
        font-size: 14px;
    }

    h1 {
        text-align: center;
        font-size: 30px;
    }

    h4 {
        text-align: center;
        font-size: 24px;
    }

    li, .pli {
        font-size: 17px;
    }

    button {
        width: 100%;
        font-size: 19px;

        opacity: 1;
    }

    .td {
        width: 100%;
        margin: 45px 0px 0 0px;
    }

    .doc {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }

    .bgh {
        font-size: 25px;
        font-weight: bold;
        margin: 30px auto;
    }

    .in {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }

    .inf {
        width: 100%;
        min-height: 1200px;
        margin-bottom: 35px;
    }

    .ot {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }

    .otz {
        margin-bottom: 35px;
        width: 100%;
    }

    .oth {
        font-size: 21px;
    }

    .otp {
        font-size: 17px;
    }

    .bt2,
    .bot {
        width: 100%;
        font-size: 21px;
    }
     
    .txt {
        padding: 5em 1em;
        width: 100%;
    }

    .dbz {
        width: 100%;
    }

    a {
        width: 100%;
    }

    .bz {
        font-size: 21px;
        background: #fff;
        color: green;
        text-shadow: none;
    }

    .par2 {
        background-position-x: 90%;
    }

    .par3 {
        background-position-x: 65%;
    }

    .uh {
        font-size: 2.5em;
    }

    .inf {
        min-height: 550px;
    }

    .inp {
        padding: 15px;
    }

    .tr {
        font-size: 19px;
    }
}
     
</style>