<template>
    <Logo />
    <But />
    <div class="tt">
        <h1>Акции</h1>
        <div class="row row-cols-1 row-cols-md-2 g-2">
            <div class="card">
                <img src="../assets/kons.jpg" class="card-img-top" alt="">
                <div class="card-body">
                    <h5 class="card-title">
                        Первичная консультация специалистов – бесплатно
                    </h5>
                    <p class="card-text">
                        
                    </p>
                </div>
            </div>
            <div class="card">
                <img src="../assets/ak1.jpg" class="card-img-top" alt="">
                <div class="card-body">
                    <h5 class="card-title">
                        Акция действует до 28 февраля 2023 года
                    </h5>
                    <p class="card-text">
                        
                    </p>
                </div>
            </div>
        </div>
        <div class="tm">
            <a href="tel:+79530197374"><button>Записаться на прием</button></a>
        </div>
    </div>
    <Footer />
</template>

<script>
import Logo from '@/components/Logo.vue'
import Footer from '@/components/Footer.vue'
import But from '@/components/But.vue'

export default {
    name: "TherapyView",
    components: {
        Logo,
        Footer,
        But,
    }
}
</script>

<style scoped>
.tt {
    min-height: 100vh;
    padding: 5vh 3.5vw;
    background-image: url(../assets/fon2.jpg);
    background-size: cover;
}

h1 {
  position: relative;
  color: green;
  text-transform: uppercase;
  font-size: 2vw;
  letter-spacing: 4px;
}

.tm {
    display: flex;
    justify-content: center;
}

.row {
    margin-top: 4vh;
}

.card {
    width: 30%;
    margin-right: 20px;
}

.g-2 {
    --bs-gutter-x: 0;
}

button {
  margin-top: 50px;
  background: white;
  border: 0.1 solid;
  border-color: red;
  color: green;
  font-size: 1.5vw;
  min-width: 250px;
  min-height: 50px;
  transition: all 0.4s ease;
  
  opacity: 0;
}

button:hover {
  background: green;
  border-color: green;
  color: white;
  transition: all 0.4s ease;
}

h5 {
    font-size: 1.7vw;
}

@media (max-width: 790px) {

    .tt {
        padding: 5em 1em;
    }

    h1 {
        margin-left: 5px;
        font-size: 24px;
    }

    h5 {
        font-size: 24px;
    }

    img {
        width: 100%;
    }

    button,
    a {
        width: 100%;
        font-size: 19px;

        opacity: 1;
    }

    .card {
        width: 100%;
        margin-right: 0px;
    }
    
}
</style>